<template>
  <div class="courseDetail">
    <el-card class="box-card">
      <h2><span></span>课程详情</h2>
      <div class="item">
        <p>课程封面</p>
        <div class="cover" v-if="courseInfo.coverImg">
          <el-image
            style="width: 100%; height: 100%"
            :src="courseInfo.coverImg"
            :preview-src-list="[courseInfo.coverImg]"
          >
          </el-image>
        </div>
      </div>
      <div class="item">
        <p>主图</p>
        <div
          class="imgbox"
          v-if="courseInfo.mainImageArr && courseInfo.mainImageArr.length > 0"
        >
          <p
            class="coverimg"
            v-for="item in courseInfo.mainImageArr"
            :key="item"
          >
            <el-image
              style="width: 100%; max-height: 100%"
              :src="item"
              :preview-src-list="courseInfo.mainImageArr"
            >
            </el-image>
            <!-- <img :src="item" alt="" /> -->
          </p>
        </div>
        <p v-else>暂无数据</p>
      </div>
      <div class="item">
        <p>课程名称</p>
        <div class="item-text">{{ courseInfo.courseName }}</div>
      </div>
      <div class="item">
        <p>课程类别</p>
        <div class="item-text">
          <!-- {{ courseInfo.courseCategory }} -->
          {{
            getDictDataLabel(
              DICT_TYPE.COURSE_CATEGORY,
              Number(courseInfo.courseCategory)
            )
          }}
        </div>
      </div>
      <!-- <div class="item">
        <p>团课开课日</p>
        <div>{{ courseInfo.responsiblePersonPhone }}</div>
      </div> -->
      <div class="item" v-if="courseInfo.courseCategory === 1">
        <p>开课日</p>
        <div class="item-text">{{ courseInfo.dayArr }}</div>
      </div>
      <div class="item" v-if="courseInfo.courseCategory === 1">
        <p>开课时间</p>
        <div class="item-text">{{ courseInfo.openTimeInfo }}</div>
      </div>
      <div class="item" v-if="courseInfo.courseCategory === 1">
        <p>上课人数</p>
        <div class="item-text">{{ courseInfo.numberLimit }}</div>
      </div>
      <div class="item">
        <p>有效期</p>
        <div class="item-text">{{ courseInfo.validityTimeText }}</div>
      </div>
      <div class="item">
        <p>课时节数</p>
        <div class="item-text">
          {{ courseInfo.courseNumberText }}
        </div>
      </div>
      <div class="item">
        <p>单节时长</p>
        <div class="item-text">{{ courseInfo.singleDuration }}分钟</div>
      </div>
      <div class="item">
        <p>预约取消时间限制</p>
        <div class="item-text">
          {{ courseInfo.reservationCancelTimeLimitText }}
        </div>
      </div>
      <!-- <div class="item">
        <p>团课上课人数</p>
        <div><img :src="courseInfo.businessLicense" alt="" /></div>
      </div> -->
      <div class="item">
        <p>原价格</p>
        <div class="item-text">￥{{ courseInfo.underlinedPrice }}</div>
      </div>
      <div class="item">
        <p>优惠价格</p>
        <div class="item-text">￥{{ courseInfo.originalPrice }}</div>
      </div>
      <div class="item">
        <p>课程详情</p>
        <div
          class="imgbox"
          v-if="
            courseInfo.courseDetailsArr &&
            courseInfo.courseDetailsArr.length > 0
          "
        >
          <p
            class="coverimg"
            v-for="item in courseInfo.courseDetailsArr"
            :key="item"
          >
            <el-image
              style="width: 100%; max-height: 100%"
              :src="item"
              :preview-src-list="courseInfo.courseDetailsArr"
            >
            </el-image>
          </p>
        </div>
        <p v-else>暂无数据</p>
      </div>
      <div class="item">
        <p>注意事项</p>
        <div class="item-text">{{ courseInfo.note }}</div>
      </div>
    </el-card>
    <el-card class="box-card">
      <h2><span></span>授课教练</h2>
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="coverImg" label="教练头像">
          <template slot-scope="{ row }">
            <div class="logo">
              <img :src="row.headImage" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="教练名称"> </el-table-column>
        <el-table-column prop="phone" label="联系电话"> </el-table-column>
        <el-table-column prop="epId" label="授课状态">
          <template slot-scope="{ row }">
            <p>
              <span
                :style="{
                  color: row.coachStatus === 0 ? '#5E83F5' : '#EE4242',
                }"
                >{{
                  getDictDataLabel(
                    DICT_TYPE.COACH_STATUS,
                    Number(row.coachStatus)
                  )
                }}</span
              >
            </p>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { getCourseDetail } from "@/api/course";
export default {
  data() {
    return {
      id: this.$route.query.id,
      courseInfo: {},
      tableData: [1],
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    // 获取基础数据
    async init() {
      const res = await getCourseDetail({ id: Number(this.$route.query.id) });
      let { result } = res;
      result.mainImageArr = result.mainImage.split(",");
      result.validityTimeText = result.validityTime
        ? `${result.validityTime}天`
        : "无限制";
      result.courseNumberText = result.courseNumber
        ? `${result.courseNumber}节`
        : "无限次数";
      result.reservationCancelTimeLimitText = result.reservationCancelTimeLimit
        ? `${result.reservationCancelTimeLimit}分钟`
        : "无限制";
      result.courseDetailsArr = result.courseDetails.split(",");
      if (result.courseCategory === 1) {
        const openTime = JSON.parse(result.openTime);
        const dayArr = openTime.openDays.split(",").map((el) => {
          return `周${this.numberToChinese(el)}`;
        });
        result.dayArr = dayArr.join("/");
        result.openTimeInfo = openTime.openTimeInfo;
      }
      this.courseInfo = result;
      this.tableData = result.courseCoachList;
    },
    numberToChinese(num) {
      let chineseNum = String(num);
      chineseNum = chineseNum
        .replace(/0/g, "零")
        .replace(/1/g, "一")
        .replace(/2/g, "二")
        .replace(/3/g, "三")
        .replace(/4/g, "四")
        .replace(/5/g, "五")
        .replace(/6/g, "六")
        .replace(/7/g, "日");

      return chineseNum;
    },
  },
};
</script>
<style lang="scss" scoped>
.courseDetail {
  display: flex;
  justify-content: space-between;
  .logo {
    display: flex;
    justify-content: center;
    img {
      width: 50px;
      height: 50px;
    }
  }
  // }
  .box-card {
    width: 49%;
    box-sizing: border-box;
    border-radius: 4px;
    h2 {
      color: #333333;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      span {
        display: block;
        width: 4px;
        height: 16px;
        background: #5e83f5;
        margin-right: 8px;
      }
    }
  }
  .item {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    p {
      font-size: 16px;
      color: #696969;
      width: 200px;
      flex-shrink: 0;
    }
    .item-text {
      font-size: 16px;
      color: #333333;
      line-height: 21px;
      font-weight: 600;
      flex: 1;
      width: 0;
      // display: block;
      word-break: break-all;
    }
    .cover {
      width: 100px;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .imgbox {
      display: flex;
      flex-wrap: wrap;
      .coverimg {
        width: 100px;
        height: 100px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
</style>
