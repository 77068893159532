import request from "@/utils/request";
// 课程列表/新上课程审核列表
const getCourseList = (params) =>
  request.post("/boxing/web/clubCourse/pageClubCourseList", params);
// 课程列表/新上课程审核列表操作课程审核状态
const setCheckCourse = (params) =>
  request.post("/boxing/web/clubCourse/operationCourseReviewStatus", params);
// 课程列表/新上课程审核列表查看详情
const getCourseDetail = (params) =>
  request.post("/boxing/web/clubCourse/webSelectCourseDetail", params);
// 课程信息变更审核列表
const getCourseModifyList = (params) =>
  request.post("/boxing/web/clubCourse/pageClubCourseModify", params);
// 课程信息变更详情
const getCourseModifyDetail = (params) =>
  request.post("/boxing/web/clubCourse/getClubCourseModifyById", params);
// 审核课程信息变更
const setCourseModifyStatus = (params) =>
  request.post("/boxing/web/clubCourse/auditClubCourseModify", params);
export {
  getCourseList,
  setCheckCourse,
  getCourseDetail,
  getCourseModifyList,
  getCourseModifyDetail,
  setCourseModifyStatus,
};
